#attraction-page {
  section.hero {
    background:
      url("../images/attraction-page/hero_bg.jpg");
    background-position: center 45%;
    background-repeat:  no-repeat;
    background-size:  cover;

    h1.title {
      font-family: $handwriting-font-family;
    }
    h1.title, h2.title {
      text-shadow: 0 1px 15px black;
    }
  }
}

