#contact-page {
  section#contact {
    i#map-marker {
      position: relative;
      top: -3rem;
    }
    div#map {
      height: 220px;
    }
  }
}
