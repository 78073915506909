#amenity-page {
  figure.image {
    height: 235px;
    img {
      position: relative;
      top: -55px;
    }
  };
  section.hero {
    background:
      url("../images/amenity-page/hero_bg.jpg");
    background-position: center 45%;
    background-repeat:  no-repeat;
    background-size:  cover;

    h1.title {
      font-family: $handwriting-font-family;
    }
    h1.title, h2.title {
      text-shadow: 0 1px 15px black;
    }
  }
  section {
    min-height: 20rem;
    &#highlights{
      background-color: color("green","lighten-5");
    }
    &#mature-woodland {
      background: url("../images/amenity-page/mature_woodland_section_bg.png") no-repeat left center,
                  url("../images/common/leaf_bg.svg") no-repeat center center fixed;
      background-size: 50%, cover;
    }
    &#fishing {
      background: url("../images/amenity-page/fishing_section_bg.png") no-repeat left 30%,
      url("../images/common/leaf_bg.svg") no-repeat center center fixed;
      background-size: 50%, cover;
    }
    &#nature-trails {
      background: url("../images/amenity-page/nature_trails_section_bg.png") no-repeat left 30%,
      url("../images/common/leaf_bg.svg") no-repeat center center fixed;
      background-size: 50%, cover;
    }
    &#sandy-beach {
      background: url("../images/amenity-page/sandy_beach_section_bg.png") no-repeat right center;
      background-color: color("green","lighten-5");
      background-size: 50%, auto;
    }
    &#playground{
      background: url("../images/amenity-page/playground_section_bg.png") no-repeat right 25%;
      background-color: color("green","lighten-5");
      background-size: 50%, auto;
    }
    &#full-hookup{
      background: url("../images/amenity-page/full_hookup_section_bg.png") no-repeat right 80%;
      background-color: color("green","lighten-5");
      background-size: 50%, auto;
    }
  }
}
