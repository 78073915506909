#home-page {
  #slider {
    &-1 {
      background-image: url("../images/home-page/slider_bg_1.jpg");
    }

    &-2 {
      background-image: url("../images/home-page/slider_bg_2.jpg");
    }

    &-3 {
      background-image: url("../images/home-page/slider_bg_3.jpg");
    }
  }

  .hero {
    background-position: center center;
    background-repeat:  no-repeat;
    background-size:  cover;

    h1.title {
      font-family: $handwriting-font-family;
    }

    h1.title, h2.title {
      text-shadow: 0 1px 15px black;
    }
  }

  #news-bar {
    background-color: color("light-green","lighten-4");
  }
  section#highlights {
    background: url("../images/home-page/abstract_1_bg.svg") no-repeat center center;
    background-size: cover;
    background-attachment: fixed;

  }
  section#activities-details {
    background: url("../images/home-page/abstract_2_bg.svg") no-repeat center center;
    background-size: cover;
    background-attachment: fixed;
  }
  section#shortcut{
    background-color: color("light-green","lighten-4");
    button {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
