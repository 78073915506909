// Utilities
//––––––––––––––––––––––––––––––––––––––––––––––––––

// General Formatting


// Graphics
.divider-line {
  //background: url("../img/utilities-divider.png") no-repeat center center;
  @include background-size(cover);
  color: $muted-font-color;
  width: 100%;
}

// Text
.no-decoration {
  color: $font-color;
  text-decoration: none;
}

// List
ul,dl,ol.no-style {
  list-style-type:none!important;
}

// Table
tr.separator > td {
  border-width: 2px 0 0;
  color: #363636;
}
