#direction-page {
  section#direction {

    i#map-marker {
      position: relative;
      top: -3rem;
    }
    div#map {
      height: 100%;
    }
  }
}
