// Variables
//––––––––––––––––––––––––––––––––––––––––––––––––––
@import "variables_colors";
@import url('https://fonts.googleapis.com/css?family=Kaushan+Script&display=swap');

// Colors
// Actual color palettes imported from separate file
$primary-color: color("light-blue","darken-4") !default;
$secondary-color: color("light-blue","darken-3") !default;
$border-color: color("grey","lighten-3") !default;
$link-color: color("blue","darken-3") !default;
$font-color: color("grey","darken-3") !default;
$secondary-font-color: color("grey","darken-2") !default;
$muted-font-color: color("grey","lighten-1") !default;
$font-awesome-color: #233f70 !default;

// Forms
$input-height: 26px !default;

// Typography
$font-family: "Roboto", "Lato", "Raleway", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$handwriting-font-family: 'Kaushan Script', cursive;

// Utilities
$space-xs:         6px;
$space-sm:        12px;
$space-md:        20px;
$space-lg:        36px;

// Misc
$global-radius:    4px !default;
