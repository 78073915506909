#seasonal-page {
  section.hero {
    background:
      url("../images/seasonal-page/hero_bg.jpg");
    background-position: center 45%;
    background-repeat:  no-repeat;
    background-size:  cover;

    h1.title {
      font-family: $handwriting-font-family;
    }
    h1.title, h2.title {
      text-shadow: 0 1px 15px black;
    }
  }
  section#community,#fun {
    background: url("../images/common/leaf_bg.svg") no-repeat center center;
    background-size: cover;
    background-attachment: fixed;
  }
  section#park {
    background-color: color("green","lighten-5");
  }
  section#apply {
    background-color: color("green","lighten-1");
  }
}
