nav {
  &.navbar {
    background-color: color("green","base") !important;
    a.navbar-item.is-active {
      background-color: color("green","lighten-1") !important;
    }
  }
  .navbar-brand > .navbar-logo > img {
    max-height: 2.5rem;
  }
}
